export const ONE_LINE_GUIDE_CHANNEL_SLICE_SIZE = 8;

export const ONE_LINE_GUIDE_ABOVE_CHANNEL_SLICE_SIZE = Math.floor(
    (ONE_LINE_GUIDE_CHANNEL_SLICE_SIZE - 1) / 2,
);

export const ONE_LINE_GUIDE_BELOW_CHANNEL_SLICE_SIZE = Math.floor(
    ONE_LINE_GUIDE_CHANNEL_SLICE_SIZE / 2,
);

export const ONE_LINE_GUIDE_INITIAL_FETCH_ASSETS_NUMBER = 36;

export const ONE_LINE_GUIDE_HALT_SEARCHING_TIME = 5 * 24 * 60 * 60 * 1000; // milliseconds

export const ONE_LINE_GUIDE_OFFSET_FROM_ORIGIN = 0;

export const ONE_LINE_GUIDE_DEFAULT_PAST_DAYS = 3 * 24;

export const ONE_LINE_GUIDE_DEFAULT_FUTURE_DAYS = 14 * 24;

export const ONE_LINE_GUIDE_STICKINESS_DURATION = 30 * 1000; // 30 seconds

export const ONE_LINE_GUIDE_TIME_SLOT_SIZE_MINUTES = 15;

export const ONE_LINE_GUIDE_FETCH_ASSETS_NUMBER = 8;

export const TAG = 'ONE_LINE_GUIDE';

export const TURBOSCROLL_INTERVAL_TIME = 25;

export const LONG_CLICK_INTERVAL_TIME = 0.5 * 1000;
