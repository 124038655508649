import {
    BaseOptionList,
    IncludeOptionItem,
    KeepAtMostOptionItem,
    KeepUntilOptionItem,
    OnePassOptions,
    OnePassValues,
    OptionItemBase,
    OptionType,
    RecordChannelOptionItem,
    RecordTypeOptionItem,
    RentOrBuyOptionItem,
    StartFromOptionItem,
    StartRecordingOptionItem,
    StopRecordingOptionItem,
    VideoQualityOptionItem,
} from 'client-code/onepass';

import { SeparatorTypes } from '../enums';
import { Locale, LocaleKeys } from '../locale';
// TODO: import from index when cyclic dependency is removed
import { TOverlayOptions } from '../types/TOverlayOptions';

type TOnePassOptions = BaseOptionList & {
    optionList?: Array<
        | IncludeOptionItem
        | KeepUntilOptionItem
        | RecordChannelOptionItem
        | RentOrBuyOptionItem
        | KeepAtMostOptionItem
        | RecordTypeOptionItem
        | StartRecordingOptionItem
        | StartFromOptionItem
        | VideoQualityOptionItem
        | StopRecordingOptionItem
    >;
};

export class DynamicOptionsEngine {
    private static mOnePassOptionsMap?: Map<string, TOnePassOptions>;

    // map of OptionType -> option key
    private static mOptionListTypeMap?: Map<OptionType, string>;

    private static mOnePassOptionsWithValues?: OnePassValues;

    private mOnePassOptionsOrder = [
        'include',
        'startFrom',
        'rentOrBuy',
        'recordType',
        'recordChannel',
        'videoQuality',
        'keepAtMost',
        'keepUntil',
        'startRecordingEarly',
        'stopRecordingLate',
    ];

    private compareValues(firstValue: any, secondValue: any): boolean {
        if (typeof firstValue === 'object' && typeof secondValue === 'object') {
            const firstValueKeys = Object.keys(firstValue).sort();
            const secondValueKeys = Object.keys(secondValue).sort();
            if (firstValueKeys.toString() !== secondValueKeys.toString()) return false;
            const isEqual: boolean = firstValueKeys.reduce(
                (accumulator, key) =>
                    accumulator && this.compareValues(firstValue[key], secondValue[key]),
                true,
            );
            return isEqual;
        }
        return firstValue === secondValue;
    }

    private createOverlayOption(
        value: TOnePassOptions,
        disabledOptions: Set<string>,
        forcedOptions: Map<string, any>,
    ): TOverlayOptions {
        const option: TOverlayOptions = {
            text: Locale.getString(
                LocaleKeys[`OPO_OPTION_${this.optionToLocaleKey(value.optionListType!)}`],
            ),
            horizontalSelectionoptions: (value.optionList as OptionItemBase[]).map(
                (item) => item.label!,
            ),
            hrBottom: !!(value as BaseOptionList).insertDividerAfter,
            separatorType: SeparatorTypes.OPTIONS,
            value: {
                optionListType: value.optionListType,
            },
        };
        if (disabledOptions.has(value.optionListType!)) {
            option.disabled = true;
        }
        if (forcedOptions.has(value.optionListType!)) {
            const forcedLabel = forcedOptions.get(value.optionListType!);
            option.initialHorizontalIndex = this.getOptionIndexFromLabel(value, forcedLabel);
        }
        return option;
    }

    // eslint-disable-next-line class-methods-use-this
    private filterForcedAndDisabledOptions(
        options: TOnePassOptions,
        forcedOptions: Map<string, any>,
        disabledOptions: Set<string>,
        value: string,
    ) {
        const optionInfo = options.optionList?.filter((item) => item.label === value)[0];
        optionInfo?.forcedSelection?.forEach((item) => {
            return forcedOptions.set(item.optionType!, item.item?.label);
        });
        optionInfo?.disables?.forEach((item) => {
            return disabledOptions.add(item.optionListType!);
        });
    }

    // eslint-disable-next-line class-methods-use-this
    private getLabelFromOptionValue(options: TOnePassOptions, value: any): string {
        let optionInfo;
        switch (options.optionListType) {
            case OptionType.IncludeType:
                optionInfo = options.optionList?.find((option: IncludeOptionItem) => {
                    return this.compareValues(option.includeValue, value);
                });
                break;
            case OptionType.StartFrom:
                optionInfo = options.optionList?.find((option: StartFromOptionItem) => {
                    return this.compareValues(option.startFrom, value);
                });
                break;
            case OptionType.RentOrBuy:
                optionInfo = options.optionList?.find((option: RentOrBuyOptionItem) => {
                    return this.compareValues(option.rentOrBuy, value);
                });
                break;
            case OptionType.RecordType:
                optionInfo = options.optionList?.find((option: RecordTypeOptionItem) => {
                    return this.compareValues(option.recordType, value);
                });
                break;
            case OptionType.Channel:
                optionInfo = options.optionList?.find((option: RecordChannelOptionItem) => {
                    return this.compareValues(option.recordChannel, value);
                });
                break;
            case OptionType.VideoQuality:
                optionInfo = options.optionList?.find((option: VideoQualityOptionItem) => {
                    return this.compareValues(option.videoQuality, value);
                });
                break;
            case OptionType.KeepAtMost:
                optionInfo = options.optionList?.find((option: KeepAtMostOptionItem) => {
                    return this.compareValues(option.keepAtMost, value);
                });
                break;
            case OptionType.KeepUntil:
                optionInfo = options.optionList?.find((option: KeepUntilOptionItem) => {
                    return this.compareValues(option.keepUntil, value);
                });
                break;
            case OptionType.StartRecording:
                optionInfo = options.optionList?.find((option: StartRecordingOptionItem) => {
                    return this.compareValues(option.startRecording, value);
                });
                break;
            case OptionType.StopRecording:
                optionInfo = options.optionList?.find((option: StopRecordingOptionItem) => {
                    return this.compareValues(option.stopRecording, value);
                });
                break;
            default:
        }
        return optionInfo.label;
    }

    // eslint-disable-next-line class-methods-use-this
    private getOptionIndexFromLabel(options: TOnePassOptions, label: string): number {
        return options.optionList?.findIndex((option) => option.label === label) || 0;
    }

    // eslint-disable-next-line class-methods-use-this
    private optionToLocaleKey(option: string): string {
        return option.replace(/[A-Z]/g, (letter) => `_${letter.toLowerCase()}`).toUpperCase();
    }

    // eslint-disable-next-line class-methods-use-this
    public clearOnePassOptions() {
        delete DynamicOptionsEngine.mOnePassOptionsMap;
        delete DynamicOptionsEngine.mOptionListTypeMap;
        delete DynamicOptionsEngine.mOnePassOptionsWithValues;
    }

    public computeOnePassOptions(currentOptions: Map<OptionType, any>): TOverlayOptions[] {
        const disabledOptions: Set<string> = new Set();
        const forcedOptions: Map<string, any> = new Map();
        for (let i = 0; i < this.mOnePassOptionsOrder.length; i += 1) {
            const optionKey = this.mOnePassOptionsOrder[i];
            const option = DynamicOptionsEngine.mOnePassOptionsMap?.get(optionKey);
            const userSelectedOption = currentOptions.get(option?.optionListType!);
            if (option && userSelectedOption) {
                this.filterForcedAndDisabledOptions(
                    option,
                    forcedOptions,
                    disabledOptions,
                    userSelectedOption,
                );
            }
        }

        const finalOptions: TOverlayOptions[] = [];
        for (let i = 0; i < this.mOnePassOptionsOrder.length; i += 1) {
            const optionKey = this.mOnePassOptionsOrder[i];
            const value = DynamicOptionsEngine.mOnePassOptionsMap?.get(optionKey);
            if (value) {
                const option = this.createOverlayOption(value, disabledOptions, forcedOptions);
                finalOptions.push(option);
            }
        }
        return finalOptions;
    }

    public getInitialValuesOfOnePassOptions(): TOverlayOptions[] {
        const finalOptions: TOverlayOptions[] = [];
        const disabledOptions: Set<OptionType> = new Set();
        const forcedOptions: Map<string, any> = new Map();

        for (let i = 0; i < this.mOnePassOptionsOrder.length; i += 1) {
            const optionKey = this.mOnePassOptionsOrder[i];
            const option = DynamicOptionsEngine.mOnePassOptionsMap?.get(optionKey);
            const initialValue = DynamicOptionsEngine.mOnePassOptionsWithValues![optionKey];

            if (option) {
                this.filterForcedAndDisabledOptions(
                    option,
                    forcedOptions,
                    disabledOptions,
                    initialValue,
                );
                const overlayOption: TOverlayOptions = {
                    text: Locale.getString(
                        LocaleKeys[`OPO_OPTION_${this.optionToLocaleKey(option.optionListType!)}`],
                    ),
                    horizontalSelectionoptions: option.optionList?.map((item) => item.label!),
                    hrBottom: !!option.insertDividerAfter,
                    separatorType: SeparatorTypes.OPTIONS,
                    value: {
                        optionListType: option.optionListType,
                    },
                };
                if (disabledOptions.has(option.optionListType!)) {
                    overlayOption.disabled = true;
                }
                const label = this.getLabelFromOptionValue(option, initialValue);

                if (forcedOptions.has(option.optionListType!)) {
                    const forcedLabel = forcedOptions.get(option.optionListType!);
                    overlayOption.initialHorizontalIndex = this.getOptionIndexFromLabel(
                        option,
                        forcedLabel,
                    );
                } else {
                    overlayOption.initialHorizontalIndex = this.getOptionIndexFromLabel(
                        option,
                        label,
                    );
                }
                finalOptions.push(overlayOption);
            }
        }
        return finalOptions;
    }

    // eslint-disable-next-line class-methods-use-this
    public getUserSelectedOnePassValues(
        options: TOverlayOptions[],
        horizontalPosition: Array<number>,
    ): OnePassValues {
        // for non-nDVR devices, service needs default values while creating onepass for
        // nDVR options, replacing default values by user selected values
        const onePassValues = { ...DynamicOptionsEngine.mOnePassOptionsWithValues };
        options.forEach((option, index) => {
            if (option.horizontalSelectionoptions) {
                const key = DynamicOptionsEngine.mOptionListTypeMap?.get(
                    option.value.optionListType,
                )!;
                const onePassOptionValues = DynamicOptionsEngine.mOnePassOptionsMap?.get(key)!;
                const optionIndex = this.getOptionIndexFromLabel(
                    onePassOptionValues,
                    option.horizontalSelectionoptions[horizontalPosition[index]],
                );
                /**
                 * cannot use optionListType or key to get user selected values
                 * because of include option. all 3 things are different
                 * "optionListType": "includeType",
                 * optionList.includeValue
                 * key: include
                 */
                const { label, disables, forcedSelection, ...userSelectedValue } =
                    onePassOptionValues.optionList![optionIndex];
                // eslint-disable-next-line prefer-destructuring
                onePassValues[key] = Object.values(userSelectedValue)[0];
            }
        });
        return onePassValues as OnePassValues;
    }

    // eslint-disable-next-line class-methods-use-this
    public setOnePassOptions(options: OnePassOptions, optionsWithValues: OnePassValues) {
        DynamicOptionsEngine.mOnePassOptionsMap = new Map();
        DynamicOptionsEngine.mOptionListTypeMap = new Map();
        DynamicOptionsEngine.mOnePassOptionsWithValues = optionsWithValues;
        const keys = Object.keys(options);
        keys.forEach((key) => {
            DynamicOptionsEngine.mOptionListTypeMap?.set(options[key].optionListType, key);
            if ((options[key] as BaseOptionList).visibility)
                DynamicOptionsEngine.mOnePassOptionsMap?.set(key, options[key]);
        });
    }
}
